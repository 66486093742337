import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import {createHashRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom"

import IndexView from './index_view'

import { reducer as libReducer } from './smart_bps/lib'
import { reducer as choiceReducer } from './smart_bps/choice'
import { reducer as adminReducer } from './admin'
import { routes as adminRoutes } from './admin'
import { reducer as authReducer } from './auth'
import { routes as authRoutes } from './auth'
import { reducer as requestReducer } from './request'
import { routes as requestRoutes } from './request'
import { reducer as serviceReducer } from './service'
import { routes as serviceRoutes } from './service'


export const store = configureStore({
    reducer: {
        lib: libReducer,
        choice: choiceReducer,
        request: requestReducer,
        service: serviceReducer,
        admin: adminReducer,
        auth: authReducer,
    },
})

const router = createHashRouter(
    createRoutesFromElements(
        <Route path="/">
            <Route index element={<IndexView />} />
            {authRoutes}
            {adminRoutes}
            {requestRoutes}
            {serviceRoutes}
        </Route>
    )
);

ReactDOM.createRoot(document.getElementById("react-root")).render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    </React.StrictMode>
);