import React from 'react'

import { BaseView, baseStateToProps } from './smart_bps/lib/base_view'
import { wrapView } from './smart_bps/lib/utils'
import { Navigate } from 'react-router-dom'

class IndexView extends BaseView {
    constructor(props) {
        super(props)
        this.auth_required = false
    }

    renderContent() {
      if(this.props.user) {
        return <Navigate to="/service" replace={true}/>
      }
      return <Navigate to="/auth/login" replace={true}/>

      // return (
      //   <div className="card">
      //     <h5 className="card-header">Добро пожаловать</h5>
      //     <div className="card-body">
      //       <p className="card-text">
      //         Это система для учета различных внутренних рабочих моментов компании СтратоСфера
      //       </p>
      //     </div>
      //   </div>
      // )
    }
}

export default wrapView(IndexView, baseStateToProps)
