/* global toastr */

import { BaseModelView } from '../smart_bps/bps/model_view'
import { stateToProps } from '../smart_bps/bps/base_view'
import { wrapView, } from '../smart_bps/lib/utils'


export default wrapView(BaseModelView, (state) => {
    return stateToProps(state, 'request')
})
