import { Link } from 'react-router-dom'
import { baseStateToProps, BaseView } from '../lib/base_view'
import { request } from '../lib/utils'
import { store } from '../../index'
import {ModalSearch} from './modal_search'


export class BaseAdminView extends BaseView {
    get model_name() {
        return this.props.router_params.model_name
    }

    initMeta() {
        const that = this
        request({
            method: 'get',
            url: `/api/admin/${this.model_name}/model_meta`,
            success: (data) => {
                data.model_name = that.model_name
                store.dispatch({ type: 'admin_model_meta', data })
            },
        })
    }

    renderTopPanelItems() {
        let perms = this.props.perms
        if(perms === undefined) {
            return null
        }

        return [
          perms.includes("create") && <li className="nav-item" key="create">
            <Link to={`/admin/${this.model_name}/new/`} className="nav-link">
              <i className="ti ti-plus ti-xs me-1"></i>
              Создать
            </Link>
          </li>,
          <li className="nav-item" key="search">
            <a className="nav-link" data-bs-toggle="modal" data-bs-target="#modal_search">
              <i className="ti ti-search ti-xs me-1"></i>
              Поиск ({this.props.list_items_count})
            </a>
          </li>
        ]
    }

    renderModals() {
        if(this.props.search_form) {
            return <ModalSearch schema={this.props.search_form}
                                data={this.props.search_data}
                                onSearch={this.onSearch}/>
        }

        return null
    }
}


/**
 * Отображает состояние хранилища redux на props view проекта
 * Вначале получаем props по умолчанию и потом добавляем туда свойства для bps приложения
 */
export function stateToProps(state) {
    const props = baseStateToProps(state)

    // Мета-данные текущей модели
    props.model_name = state.admin.model_name
    props.model_title = state.admin.model_title
    props.table_headers = state.admin.table_headers
    props.search_form = state.admin.search_form
    props.search_presets = state.admin.search_presets
    props.form_schema = state.admin.form_schema
    props.attrs = state.admin.attrs
    props.perms = state.admin.perms

    // Данные о состоянии поиска
    props.search_data = state.admin.search_data
    props.search_preset = state.admin.search_preset

    // Элементов на текущей странице
    props.list_items = state.admin.list_items
    props.list_items_count = state.admin.list_items_count
    props.list_page = state.admin.list_page || 1
    props.list_count_pages = state.admin.list_count_pages
    props.list_has_next_page = state.admin.list_has_next_page

    return props
}
