/* global $ */

import React, { Component } from 'react'
import { FileListBlock as FileListBlockBase } from '../smart_bps/bps/files'
import classNames from 'classnames'


export class FileListBlock extends FileListBlockBase {
  renderSchemaItem(item) {
    const that = this

    // Заголовок (если filetype нет)
    if (item.filetype === undefined) {
      return (
        <li className="list-group-item p-1 px-3" key={item.title}>
          <strong>{item.title}</strong>
        </li>
      )
    }

    const file_list = this.getFilesByType(item.filetype)
    const files_show = that.state.showTypes.includes(item.filetype)

    const title_class = classNames("flex-grow-1 pointer",
      {"text-success": item.color == "green"},
    )

    return (
      <li className="list-group-item p-1 px-3" key={item.title}>
        <div className="d-flex justify-content-between">
          <div className={title_class} onClick={() => { this.toggleFilesInType(item.filetype) }}>
            {item.title}
          </div>
          <div className="ti ti-cloud-upload me-1 pointer" onClick={() => { that.uploadHandler(item.filetype) }}></div>
          <span className="badge bg-secondary rounded-pill px-2">{file_list.length}</span>
        </div>
        {files_show && <div className="d-flex flex-wrap">
          {file_list.map(this.renderFile)}
        </div>}
      </li>
    )
  }

}
