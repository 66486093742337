/* global toastr */

import { BaseModelView } from '../smart_bps/bps/model_view'
import { stateToProps } from '../smart_bps/bps/base_view'
import { wrapView, } from '../smart_bps/lib/utils'
import { FileListBlock } from './files'


class ServiceView extends BaseModelView {

  formChange(name, value) {
    const that = this

    this.setState({ form_data: { ...this.state.form_data, [name]: value } }, () => {

      // После изменения некоторых полей, происходит всякое разное
      if ([
        "assigned_d_service_plan",
        "d_service_plan",
        "service_type_id",
        "payment_form_id",
        "service_status_id",
        "fix_reason_id",
        "auto_real_cost_calculated",
        "auto_death_id",
        "executor_id",
        "check_result_id",
        "is_signed",
        "need_calculation",
        "realty_fire",
        "realty_damage_move",
        "realty_damage_equipment",
        "realty_damage_structure",
        "realty_damage_root",
        "realty_damage_add_building",
        "realty_minor_damage",
        "realty_damage_ext_decor",
        "realty_full_damage",
        "realty_struct_inspect",
        "realty_struct_inspect_area_id",
        "realty_struct_inspect_root",
        "visit_remoteness_fact",
        "city_departure_fact_id",
        "calc_software_id",
        "declarant_id",
        "has_departure",
        "d_service_dep_fact",
        "is_agreed_with_client",
        "ogiginal_docs_povided",
        "d_provided_original_docs",
        "sitting_result_id",
        "add_hours",
        "disable_fine",
        "inspection_auto_video_filmed",
        "service_mileage_checked",
        "need_recension_report",
        "auto_d_use_calc",
      ].includes(name)) {
        that.saveObject()
      }
    });
  }

  renderContentLeftAction() {
    return (
      <div className="card mb-3" key="actions">
        <div className="card-body p-2">
          <span className="btn btn-outline-primary waves-effect p-2 me-2" onClick={this.saveObject}>
            <span className="ti ti-xs ti-check me-1"></span>
            Сохранить
          </span>

          {this.state.phone_list.map((phone_num) => {
            return <span className="p-2 me-2">
              <a href={`tel:${phone_num}`}>{phone_num}</a>
            </span>
          })}

        </div>
      </div>
    )
  }

  renderEventBlock() {
    return null
  }

  renderContent() {
    return <div className="row" key="content">
      <div className="col-lg-8">
        {/* {this.renderInfo()} */}
        <FileListBlock
          project_name={this.project_name}
          object_id={this.object_id}
          files_schema={this.state.files_schema}
          files={this.state.files}
          fileUploaded={this.fileUploaded}
        />
        {this.renderContentLeft()}
      </div>
      <div className="col-lg-4">
        {this.renderComments()}
      </div>
    </div>
  }
}


export default wrapView(ServiceView, (state) => {
  return stateToProps(state, 'service')
})
