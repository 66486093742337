import { createReducer } from '@reduxjs/toolkit'
import { storeReducerBuilderChange, initialState } from '../smart_bps/bps'
import { Route } from 'react-router'
import React from 'react'
import ListView from './list_view'
import ModelView from './model_view'


export const reducer = createReducer(initialState, (builder) => {
    storeReducerBuilderChange(builder, 'service')
})


export const routes = (
    <Route path="service">
        <Route index element={<ListView />} />
        <Route path=":object_id" element={<ModelView />} />
    </Route>
)
