/* global toastr */

import React from 'react'
import { Link } from 'react-router-dom'

import { request } from '../lib/utils'
import { store } from '../../index'
import { Paginator } from '../lib/components'
import { BaseBpsView } from './base_view'
import {format_attr_value} from '../lib/utils'


export class BaseListView extends BaseBpsView {

    constructor(props) {
        super(props)
        this.onSearch = this.onSearch.bind(this)
        this.updateItems = this.updateItems.bind(this)
        this.updateItemsProcess = false
    }

    onSearch(data) {
        store.dispatch({ type: `${this.project_name}_search`, data})
    }

    updateItems(page_num) {
        if(this.updateItemsProcess === true) {
            return
        }

        const that = this
        this.updateItemsProcess = true
        request({
            method: 'post',
            url: `/api/${this.project_name}/get_page`,
            data: {
                page_num: page_num,
                filter_data: this.props.search_data || {},
                preset: this.props.search_preset || "",
            },
            success: (data) => {
                store.dispatch({
                    type: `${that.project_name}_list_change`,
                    data: {...data, page: page_num},
                })
                this.updateItemsProcess = false
            },
            error: (data) => {
                toastr.error(data.detail, "Ошибка обновления")
                this.updateItemsProcess = false
            },
        })
    }

    renderBreadcrumbs() {
        return (
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/">Домой</Link>
                    </li>
                    <li className="breadcrumb-item active">{this.props.project_title}</li>
                </ol>
            </nav>
        )
    }

    renderContent() {
        let view = this

        // Мета-данные проекта не пришли
        if (!this.props.project_title) {
            this.initMeta()
            return null
        }

        // Пока список с объектами не пришел, выходим и ждем его
        if (view.props.list_items === undefined) {
            view.updateItems(view.props.list_page)
            return null
        }

        function renderTh(attr_name) {
            const attr = view.props.attrs[attr_name]
            return <th key={attr_name}>{attr.title}</th>
        }

        function renderTr(row) {
            return <tr key={row.id}>
                <td><Link to={`/${view.project_name}/${row.id}/`}>{row.id}</Link></td>
                {view.props.table_headers.map((attr_name) => {return renderTrTd(row, attr_name)})}
            </tr>
        }

        function renderTrTd(row, attr_name) {
            const attr = view.props.attrs[attr_name]
            return <td key={attr_name}>{format_attr_value(attr, row[attr_name])}</td>
        }

        if(this.props.list_items.length === 0) {
          return <div className="card mb-3">
            <div className="card-body p-3">
              Данные не найдены. Уточните условия отбора и поиска.
            </div>
          </div>
        }

        return [
          <div className="card mb-3">
              <div className="table-responsive text-nowrap">
                  <table className='table table-sm'>
                      <thead><tr>
                          <th key="id">#</th>
                          {this.props.table_headers.map(renderTh)}
                      </tr></thead>
                      <tbody className="table-border-bottom-0">
                          {this.props.list_items.map(renderTr)}
                      </tbody>
                  </table>
              </div>
          </div>,

          <Paginator current_page={this.props.list_page}
                      count_pages={this.props.list_count_pages}
                      has_next={this.props.list_has_next_page}
                      setPage={this.updateItems}/>
      ]
    }

}
